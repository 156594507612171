.t2e-survey{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t2e-survey-inner{
  background: white;
  box-shadow: 0 0 0.25cm rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  margin: 10px;
  padding: 30px 30px 0px;
  max-width: 550px;
  min-width: 335px;
  width:70%
}

.t2e-survey-0-text{
  text-align: center;
  font-weight: bold;
  width: fit-content;
  margin: auto;
  padding: 10px 0;
  font-size: larger;
}

.t2e-survey-btns{
  display: flex;
  justify-content: center;
  margin: 5px;
}

.t2e-survey-confirm{
  width: 120px;
  cursor: pointer;
  border-radius: 20px;
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
  padding: 1px;
  margin: 2px 15px;
}

.t2e-survey-reject{
  width: 120px;
  cursor: pointer;
  border-radius: 20px;
  background: rgba(151, 151, 151);
  padding: 1px;
  margin: 2px 15px;
}

.t2e-survey-confirm-text{
  padding: 10px 20px;
  color:rgb(31, 103, 176);
  font-weight: bold;
  background: white;
  border-radius: 20px;
  text-align: center;
}

.t2e-survey-confirm-text:hover {
  color: white;
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
}

.t2e-survey-reject-text{
  padding: 10px 20px;
  color:rgb(151, 151, 151);
  font-weight: bold;
  background: white;
  border-radius: 20px;
  text-align: center;
}

.t2e-survey-reject-text:hover {
  color: white;
  background: rgba(151, 151, 151);
}

.t2e-survey-1-text{
  text-align: center;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.t2e-survey-1-numbers{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0 5px;
}

.t2e-survey-1-num{
  text-align: center;
}

.t2e-survey-range{
  flex-grow: 1;
  min-width: 350px;
  margin-bottom: 30px;
}

.t2e-survey-range-input{
  width: 100%;
  margin: 5.8px 0;
  padding: 0;
}

.t2e-survey-range-input:focus {
  outline: none;
}

.t2e-survey-range-input::-webkit-slider-runnable-track {
  border-radius: 25px;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}

.t2e-survey-range-input::-webkit-slider-thumb {
  margin-top: -4px;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle at center, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}

.t2e-survey-777-text{
  padding: 10px 0 20px 0;
  font-size: larger;
  font-weight: bold;
  text-align: center;
}

.t2e-survey-999{
  padding: 10px 0 20px 0;
  font-size: larger;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.t2e-survey-999-text{
  padding: 10px 0 20px 0;
  font-size: larger;
  font-weight: bold;
  text-align: center;
  color: rgb(31, 103, 176);
}

.t2e-survey-10{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 20px 5px;
}

.t2e-survey-10-title{
  text-align: center;
  font-size: larger;
  font-weight: bold;
  margin: 0px 0 10px;
}

.t2e-survey-10-subtitle{
  margin: 0px 0 10px;
}

.t2e-survey-11-text{
  padding: 10px 0 20px 0;
  text-align: center;
}

.t2e-survey-3-innerform{
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh;
}

.t2e-survey-3-form-title{
  font-weight: bold;
  text-align: center;
}

.t2e-survey-formbtns{
  display: flex;
  justify-content: center;
  margin: 5px;
}

.t2e-survey-formbtns .t2e-survey-confirm{
  width: 250px;
}

.t2e-survey-10-btns .t2e-survey-confirm{
  width: 250px;
}

.t2e-survey-3-form-row-left{
  
}

.t2e-survey-3-form-row{
  display: flex;
  flex-direction: row;
  margin: 15px;
  min-width: 80%;
}

.t2e-survey-3-form-row-right{
  flex-grow: 1;
}

.t2e-survey-3-form-row-title{
  font-weight: bold;
  margin: 5px 0;
}

.t2e-survey-3-form-row-detail-title{
  margin: 5px 0;
}

.t2e-survey-3-form-row-options{
  display: flex;
  flex-direction: column;
}

.t2e-survey-3-form-row-option{
  display: flex;
  flex-direction: row;
  margin: 2px 0;
}

.t2e-survey-3-form-row-option-left{

}

.t2e-survey-3-form-row-option-right{

}

.t2e-survey-3-form-row-option-others{
  display: flex;
  flex-direction: row;
}

.t2e-survey-3-form-row-option-others-right{
  display: flex;
  flex-direction: row;
}

.t2e-survey-3-form-row-option-others-input input{
  margin: 0 10px;
  border-width: 0 0 1px 0;
}

.t2e-survey-3-form-row-option-others-input input:focus{
  outline: none;
}

.t2e-survey-3-form-row-others-textarea-border{
  margin: 5px;
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
  padding: 1px;
  border-radius: 12px;
  height: 20vh;
}

.t2e-survey-3-form-row-others-textarea{
  width: 100%;
  height: 100%;
}

.t2e-survey-3-form-row-others-textarea textarea{
  vertical-align: text-top;
  border-radius: 12px;
  width: calc(100% - 10px);
  max-width: calc(100% - 10px);
  height: calc(100% - 6px);
  max-height: calc(100% - 6px);
  border: none;
  resize: none;
  padding: 3px 5px;
}

.t2e-survey-3-form-row-others-textarea textarea:focus{
  outline: none;
}

.t2e-survey-form-errormsg{
  height: 25px;
  text-align: center;
  font-weight: bold;
  color:red;
}

.t2e-survey-3-close{
  position: relative;
  cursor: pointer;
  float: right;
  margin-top: -20px;
  margin-right: -15px;
  color: rgb(31, 103, 176);
}

.t2e-survey-888{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 20px 5px;
}

.t2e-survey-888-title{
  text-align: center;
  font-size: larger;
  font-weight: bold;
  margin: 0px 0 10px;
}

.t2e-survey-888-text{
  padding: 10px 0 20px 0;
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
  color: rgb(31, 103, 176);
}

.t2e-survey-3-form-input-border{
  margin: 5px;
  background: linear-gradient(90deg, rgb(67, 169, 223) 0%, rgb(31, 103, 176) 75.75%, rgb(31, 103, 176) 100%);
  padding: 1px;
  border-radius: 12px;
  width: 100%;
}

.t2e-survey-20{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 20px 5px;
}

.t2e-survey-20-title{
  text-align: center;
  font-weight: bold;
  margin: 0 0 15px;
  font-size: larger;
}

.t2e-survey-20-form{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: stretch;
  width: 100%;
  margin: 5px;
}

.t2e-survey-20-form-title{
  width: 70%;
  margin: 0 20px;
  text-align: left;
  font-weight: bold;
}

.t2e-survey-20-form-error{
  color:red;
}

.t2e-survey-20-form-input{
  width: 100%;
  height: 100%;
}

.t2e-survey-20-date{
  margin: 10px 0;
}

.t2e-survey-20-form-input input{
  vertical-align: text-top;
  border-radius: 12px;
  width: calc(100% - 10px);
  max-width: calc(100% - 10px);
  height: calc(100% - 6px);
  max-height: calc(100% - 6px);
  border: none;
  resize: none;
  padding: 3px 5px;
}

.t2e-survey-20-form-input input:focus{
  outline: none;
}

@media only screen and (max-width: 768px){
  .t2e-survey-inner{
    min-width: auto;
  }

  .t2e-survey-3-form{
    margin: 0 -15px;
  }

  .t2e-survey-range{
    flex-grow: 1;
    min-width: auto;
  }

  .t2e-survey-confirm-text{
    padding: 5px 15px;
    font-size: small;
  }

  .t2e-survey-reject-text{
    padding: 5px 15px;
    font-size: small;
  }

  .t2e-survey-0-text{
    font-size: medium;
  }

  .t2e-survey-10-title{
    font-size: medium;
  }

  .t2e-survey-3-form-title{
    font-size: small;
  }

  .t2e-survey-3-form-row-title{
    font-size: small;
  }

  .t2e-survey-3-form-row-detail-title{
    font-size: small;
  }

  .t2e-survey-3-form-row-option-right{
    font-size: small;
  }
  
  .t2e-survey-3-form-row-option-others-right{
    font-size: small;
  }
}

.ie.t2e-survey-0-text{
  display: table;
}