.zchat-input {
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 10px 15px;
}

.zchat-inputbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  min-width: 50px;
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgba(200, 200, 200, 1);
}

.zchat-inputfield {
  width: 100%;
  margin: 5px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zchat-inputfieldinput {
  font-size: medium;
  width: 100%;
  resize: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
}

.zchat-inputfieldinput:focus {
  outline: none;
}

.zchat-inputfieldinput::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.zchat-send {
  margin: 5px 10px;
  padding: 5px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: rgb(212, 120, 0);
  background: rgb(255, 196, 141);
}

.zchat-send:hover {
  color: rgb(255, 123, 30);
  background: rgba(200, 123, 30, 0.2);
}

.zchat-send img {
  width: 100%;
  height: 100%;
}

.TownGas.zchat-send {
  margin: 5px 10px;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.TownGas.zchat-send:hover {
  background-color: transparent;
  filter: invert();
}

.zchat-inputfield.en textarea {
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 768px) {
  .zchat-input {
    display: flex;
    flex-direction: row;
    padding: 10px 5px;

    position: sticky;
    bottom: 0;
  }

  .zchat-inputfield {
    margin: 5px 5px;
  }

  .zchat-inputfieldinput {
    font-size: 12px;
  }

  .TownGas.zchat-send {
    margin: 5px 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .zchat-inputfieldinput {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: auto;
  }

  .zchat-inputbox {
    height: 35px;
  }
}
