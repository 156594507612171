.t2e-disclaimer {
  position: fixed;
  max-width: 580px;
  top: 70px;
  height: calc(100vh - 90px);
  background: rgba(0, 0, 0, 0.3);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t2e-disclaimer-inner {
  background: white;
  box-shadow: 0 0 0.25cm rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  width: 80%;
  margin: -30px 10px 10px 10px;
  padding: 10px 10px 0px 10px;
}

.t2e-disclaimer-header {
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

.t2e-disclaimer-content {
  padding: 15px;
  overflow-y: auto;
  max-height: 70vh;
}

.t2e-disclaimer-content .zc-tr {
  display: table-row;
  margin: 5px;
}

.t2e-disclaimer-content .zc-td {
  display: table-cell;
  padding: 5px;
}

.t2e-disclaimer-btns {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.t2e-disclaimer-confirm {
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgb(67, 169, 223) 0%,
    rgb(31, 103, 176) 75.75%,
    rgb(31, 103, 176) 100%
  );
  padding: 1px;
  margin: 2px 15px;
}

.t2e-disclaimer-reject {
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
  background: rgb(151, 151, 151);
  padding: 1px;
  margin: 2px 15px;
}

.t2e-disclaimer-confirm-text {
  padding: 10px 20px;
  color: rgb(31, 103, 176);
  font-weight: bold;
  background: white;
  border-radius: 20px;
  text-align: center;
}

.t2e-disclaimer-confirm-text:hover {
  color: white;
  background: linear-gradient(
    90deg,
    rgb(67, 169, 223) 0%,
    rgb(31, 103, 176) 75.75%,
    rgb(31, 103, 176) 100%
  );
}

.t2e-disclaimer-reject-text {
  padding: 10px 20px;
  color: rgb(151, 151, 151);
  font-weight: bold;
  background: white;
  border-radius: 20px;
  text-align: center;
}

.t2e-disclaimer-reject-text:hover {
  color: white;
  background: rgb(151, 151, 151);
}

@media only screen and (max-width: 768px) {
  .t2e-disclaimer-header {
    font-size: medium;
    white-space: nowrap;
  }

  .t2e-disclaimer-inner {
    max-height: 90vh;
  }

  .t2e-disclaimer-content {
    overflow-y: auto;
    font-size: smaller;
    max-height: 60vh;
  }

  .t2e-disclaimer-confirm-text {
    padding: 5px 15px;
    font-size: small;
  }

  .t2e-disclaimer-reject-text {
    padding: 5px 15px;
    font-size: small;
  }

  .t2e-disclaimer {
    position: fixed;
    max-width: 580px;
    top: 40px;
    height: calc(100vh - 40px);
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .t2e-disclaimer-content {
    max-height: 55vh;
  }
}

.ie.disclaimertable td {
  vertical-align: top;
}
